<script>
import { computed } from 'vue'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useExtensions } from '/~/composables/extensions'
import { usePoints } from '/~/composables/points'
import { useUser } from '/~/composables/user'
import { useMembership } from '/~/templates/bill-payments/composables'

export default {
  name: 'app-header-points',
  components: {
    BaseAction,
    BaseIcon,
    BaseLoader,
  },
  setup() {
    const { tierBadgeAction, showTierBadge } = useCms()
    const { formattedPointsBalance, pointsLoading } = usePoints()
    const { user } = useUser()
    const { getManifestByName } = useExtensions()
    const { isMembershipSwitchingEnabled } = useMembership()

    const isPointsEnabled = computed(() => {
      return Boolean(getManifestByName('points'))
    })

    return {
      tierBadgeAction,
      showTierBadge,
      formattedPointsBalance,
      pointsLoading,
      user,
      isPointsEnabled,
      isMembershipSwitchingEnabled,
      ui,
    }
  },
  methods: {
    onClickMembership() {
      if (this.tierBadgeAction && this.tierBadgeAction.type !== 'none') {
        return
      }
      if (this.isMembershipSwitchingEnabled) {
        modal.show('init-tier-options-modal')
      }
    },
    onClickPoints() {
      this.$router.push({ hash: '#profile-home' })
    },
  },
}
</script>

<template>
  <div
    v-if="isPointsEnabled && showTierBadge"
    class="group relative mr-5 inline-flex h-12 items-center justify-center overflow-hidden rounded-full bg-eonx-neutral-50 font-bold"
    :class="{
      'pointer-events-none': !isMembershipSwitchingEnabled,
    }"
  >
    <component
      :is="tierBadgeAction ? 'base-action' : 'div'"
      v-bind="tierBadgeAction"
      look="link"
      class="group/badge"
      classes="hover:!opacity-100"
      @click="onClickMembership"
    >
      <div
        class="flex items-center rounded-l-full py-2.5 pl-6 pr-[15px]"
        :class="{
          'cursor-pointer group-hover/badge:border-r group-hover/badge:border-primary group-hover/badge:bg-primary group-hover/badge:text-white':
            isMembershipSwitchingEnabled || tierBadgeAction,
        }"
      >
        <base-icon
          svg="v2/custom/points-token"
          :size="ui.desktop ? 26 : 20"
          class="text-primary group-hover/badge:text-white"
        />
        <p
          class="ml-2.5 border-current text-lg font-bold text-eonx-neutral-800 group-hover/badge:text-white"
        >
          {{ user.membershipName }}
        </p>
      </div>
    </component>
    <div class="h-[30px] border-l border-primary" />
    <base-action
      class="flex cursor-pointer items-baseline space-x-[5px] rounded-r-full border-primary py-2.5 pl-[15px] pr-6 text-eonx-neutral-800 hover:border-l hover:bg-primary hover:text-white"
      type="action"
      @click="onClickPoints"
    >
      <template v-if="!pointsLoading">
        <p class="text-lg font-bold">
          {{ formattedPointsBalance }}
          <span class="text-xs">PTS</span>
        </p>
      </template>
      <base-loader v-else size="sm" class="p-px" />
    </base-action>

    <slot />
  </div>
</template>
